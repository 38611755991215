import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';
// import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import { simplenewsNewsletter, simplenewsSubscriber } from './../../Shop/ext/redux/actions/user'
// import CommerceHelper from './../../../../Shop/s4n/utils/commerce-helper'
// import Preloader from './../../../../Preloader'
// import Product from './../../../../../../model/commerce/product'
// import ProductImage from './../../../../Shop/Product/image'
// import ProductPrice from './../../../../Shop/Product/price'
import { DisplayFormikState as ObjectRenderer } from "./../../../forms/formikHelper"
import Breadcrumb from './../Breadcrumb'
import Preloader from './../../Preloader'


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { user } = props;

    const simplenews_newsletter = `default`; // name od the default newsletter in Drupal
    const authorization = props.drupalOauthClient.isLoggedIn();
    const loggedInUserMail = user?.user_profile?.attributes?.mail || null;

    // const [simplenewsNewsletter, setSimplenewsNewsletter] = useState(null);
    // const [subscriptionsStatus, setSubscriptionsStatus] = useState(null);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        componentDidMount();
    }, [loggedInUserMail]);

    const componentDidMount = async () => {
// props.onSimplenewsNewsletter(authorization, simplenews_newsletter);

        if (loggedInUserMail) {
            await props.onSimplenewsSubscriber(authorization, 'GET', null, loggedInUserMail, {target_id: simplenews_newsletter}); // update store state

            // let fetchedSubscriptionsStatus = false;
            // await props.onSimplenewsSubscriber(authorization, 'GET', null, loggedInUserMail, {target_id: simplenews_newsletter}); // update store state
            // if (user?.simplenews_subscriber) {
            //     fetchedSubscriptionsStatus = user?.simplenews_subscriber?.subscriptions[0]?.status;
            // }
            // setSubscriptionsStatus(fetchedSubscriptionsStatus);

            setLoadingData(false)
        }
    }

    if (loadingData) {
        return <Preloader />;
    }

    const subscriptions = user?.simplenews_subscriber?.subscriptions;
    const subscriptionsStatus = subscriptions && subscriptions.length ? subscriptions[0]?.status : false;
    // console.log(subscriptionsStatus)
    // console.log(!subscriptionsStatus ? 1 : 0)

    return (
        <React.Fragment>

            <section class="user-dashboard section">
                <div class="container">
                    <div class="row">
                    <div class="col-md-12">
                        <Breadcrumb activeStep={`DASHBOARD_NEWSLETTER`} />
                        <div class="dashboard-wrapper user-dashboard">
                            <h1 className={`mb-3`}>Newsletter</h1>

                            {/* @todo: Create a loop of all defined newsletters here obtained from: user?.simplenews_newsletter */}

                            <input id={`simplenews_subscriber`} _className="form-control" type={`checkbox`} value={subscriptionsStatus} checked={subscriptionsStatus} onChange={e => props.onSimplenewsSubscriber(authorization, 'PATCH', null, loggedInUserMail, {target_id: simplenews_newsletter, status: !subscriptionsStatus ? 1 : 0})} />
                            {` `}
                            <label for={`simplenews_subscriber`}>Status subskrypcji newslettera</label>
                            
                            {/* <br />
                            simplenews_subscriber:
                            <ObjectRenderer {...user?.simplenews_subscriber} />
                            simplenews_newsletter:
                            <ObjectRenderer {...user?.simplenews_newsletter} /> */}
                        </div>
                    </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
      user: state.shopReducers.user,
    }
};

const mapDispatchToProps = dispatch => ({
    // Get all newsletters defined in the CMS and details about them as an array
    onSimplenewsNewsletter: (authorization, simplenews_newsletter) => dispatch(simplenewsNewsletter(authorization, simplenews_newsletter)),

    // Get information wether user is subscribed to a specific newsletter or it can PATCH sunscription to certain newsletetr switch ON / OFF
    onSimplenewsSubscriber: (authorization, method, status, mail, subscriptions) => dispatch(simplenewsSubscriber(authorization, method, status, mail, subscriptions)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(Component));