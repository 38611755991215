import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'
import { jsonapiClient } from 's4n-jsonapi-client'

const suppressException = process.env.SOFT4NET_CREATE_ACTION_THUNK_SUPPRESS_EXCEPTION === `true` ? true : false;

export const orderFetch = createActionThunk('ORDER_FETCH', async (authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    // const { order: { items, included } } = state.shopReducers;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'order_fetch', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)

export const orderItemFetch = createActionThunk('ORDER_ITEM_FETCH', async (authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    // const { order: { items, included } } = state.shopReducers;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'order_item_fetch', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)