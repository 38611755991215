import React from 'react';
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import FormLoginRegisterReset from './../../../forms/AccountManagement/LoginRegisterReset'

  
class Component extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.t = this.props.intl.formatMessage;
    // }

    render() {
        const { intl: { formatMessage: t } } = this.props;

        return (
            <React.Fragment>
               <div className="container my-5">
                    <div className="row">
                        <div className="col">
                            <div className="row flex-column align-content-center user-password-reset">
                                <div className="col col-lg-6">
                                    <h1>Nie pamiętasz hasła?</h1>
                                </div>
                                <div className="col col-lg-6">
                                    <FormLoginRegisterReset context={`password_reset`} emailConfirm={false} />
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(withDrupalOauthConsumer(Component))