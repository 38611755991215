import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../components/layout';

import UserRegister from './../../components/s4n/AccountManagement/Register';
import UserLogin from './../../components/s4n/AccountManagement/Login';
import UserPasswordReset from './../../components/s4n/AccountManagement/PasswordReset';
import PrivateRoute from './../../components/s4n/AccountManagement/PrivateRoute';
import Profile from './../../components/s4n/AccountManagement/Profile';
import Orders from './../../components/s4n/AccountManagement/Commerce/Orders'
import Wishlist from './../../components/s4n/AccountManagement/Commerce/Wishlist'
import Newsletter from './../../components/s4n/AccountManagement/Newsletter'

const User = () => {
  return (
    <Layout>
       
        <UserLogin />

    </Layout>
  )
}

export default User;