import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { orderFetch, orderItemFetch } from './../../../Shop/ext/redux/actions/order'
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import withDrupalOauthConsumer from './../../../../drupal-oauth/withDrupalOauthConsumer'
import OrderItems from './Details/OrderItems'
// import CommerceHelper from './../../../Shop/s4n/utils/commerce-helper'
import Preloader from './../../../Preloader'
import Product from './../../../../../model/commerce/product'
import ProductImage from './../../../Shop/Product/image'
import GeneralUtils from './../../../../../utils/common'

import { DisplayFormikState as ObjectRenderer } from "./../../../../forms/formikHelper"
import Breadcrumb from './../../Breadcrumb'


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    // props.onOrderFetch(authorization); // @todo: run once at app start!!! running it here is not efficient

    const { orders: { items, included }, dispatch } = props;

    // we don';t need this check since we are inside <PrivateRoute /> component that check this for us!!!
    // if (!props.userAuthenticated) {
    //     return null;
    // }

    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {       
        const authorization = props.drupalOauthClient.isLoggedIn();
        await props.onOrderFetch(authorization); // @todo: run once at app start!!! running it here is not efficient

        // const wishlist = await props.onOrderFetch(authorization);
        // // console.log(wishlist)
        // if (wishlist.included) {
        //     const wishlist_commerce_product_variation = wishlist.included.filter(item => item.attributes);
        //     setWishlist(wishlist_commerce_product_variation);
        // }

        setLoadingData(false);
    }

    if (loadingData) {
        return <Preloader />;
    }

// console.log(included) // we should use commerce_product_variation--default commerce_product_variation--phone
// return null;

    // if (!(included)) {
    //     return <Preloader />
    // }

    const mappedIncludes = getMappedIncludes(included);

// console.log(mappedIncludes)
// return null;

// const orderItems = getRelationshipFromMappedIncludes(items, 'order_items', mappedIncludes);
// console.log(orderItems)
// return null;

// console.log(items)
// return null;

    return (
        <React.Fragment>

            <section class="user-dashboard section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <Breadcrumb activeStep={`DASHBOARD_ORDERS`} />
                            <div class="dashboard-wrapper user-dashboard">

                                {items.length === 0 ?
                                    <React.Fragment>
                                        <p className={`mb-0`}>Brak elementów</p>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {/* Order ID */}
                                                            Numer zamówienia
                                                        </th>
                                                        <th>
                                                            {/* Date */}
                                                            Data zamówienia
                                                        </th>
                                                        {/* <th>
                                                            Items
                                                            Ilość produktów
                                                        </th> */}
                                                        <th>
                                                            {/* Total Price */}
                                                            Wartość zamówienia
                                                        </th>
                                                        <th>Status</th>
                                                        <th>
                                                            {/* Details */}
                                                            Produkty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                {items.map((item, index) => {

// console.log(item)
// console.log(item.relationships.order_items)
// return null;   

                                                    /**
                                                     * @see: https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/includes#s-fetch-all-at-once-using-include
                                                     * Also, notice how there is now a data key in each relationship object. 
                                                     * That let's you correlate the included resource objects with the resource objects that referenced them.
                                                     * 
                                                     * data field in JSON:API gets populated when we use incude in request
                                                     */
                                                    const itemRelationship = item?.relationships?.order_items || null;
                                                    // const itemsRelationship = items.relationships.order_items // was
                                                    if (itemRelationship?.data?.length === 0) {
                                                        return (
                                                            <div className={`container`} key={`no_items`}>
                                                                <div className={`row`}>
                                                                    {/* <div className={`col-sm-12`}><p>No items within this order</p></div> */}
                                                                    <div className={`col-sm-12`}><p>Brak elementów</p></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    const orderItems = getRelationshipFromMappedIncludes(item, 'order_items', mappedIncludes);
                                                    if (null === orderItems) {
                                                        return null;   
                                                    }

// console.log(orderItems)
// return null;   

                                                    // if (!orderItems.relationships) {
                                                    //     return null;   
                                                    // }

                                                    const orderState2BadgeType = {
                                                        draft: `badge-primary`,
                                                        fulfillment: `badge-info`,
                                                        completed: `badge-success`,
                                                        canceled: `badge-danger`,
                                                    }

                                                    let dateOrderCompleted = `Do weryfikacji...`;
                                                    if (item.attributes.completed) {
                                                        dateOrderCompleted = GeneralUtils.dateFormat(item.attributes.completed, null, intl.locale);
                                                    }

                                                    return (
                                                        <React.Fragment>
                                                        <tr>
                                                            <td>
                                                                {item.attributes.order_number ? 
                                                                    <React.Fragment>
                                                                        #{item.attributes.order_number}
                                                                    </React.Fragment>
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {/* {item.attributes.completed} */}
                                                                {dateOrderCompleted}
                                                            </td>
                                                            {/* <td>{orderItems.length}</td> */}
                                                            <td>{item.attributes.order_total.total.formatted}</td>
                                                            <td>
                                                                <span className={`badge ${orderState2BadgeType[item.attributes.state]}`}>
                                                                    {t({ id: `soft4net_shop_commerce_account_management_commerce_orders_status_${item.attributes.state}` })}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {/* <a href="#" className="btn btn-sm btn-outline-primary">View</a> */}
                                                                <OrderItems orderItems={orderItems} mappedIncludes={mappedIncludes} />
                                                            </td>
                                                        </tr>

                                                        {/* {false && 
                                                            <div className={`row`}>
                                                                <div className={`col-12`}>
                                                                    <table className={`table my-4`}>
                                                                        <tbody>
                                                                            <tr key={item.id}>
                                                                                <td className="cart-block--offcanvas-cart-table__title">
                                                                                    {item.attributes.drupal_internal__order_id}<br />
                                                                                    {item.attributes.mail}<br />
                                                                                    {item.attributes.completed}<br />
                                                                                    {item.attributes.order_total.total.formatted}<br />
                                                                                </td>
                                                                                <td className="cart-block--offcanvas-cart-table__price">
                                                                                    shipping_information:<br />
                                                                                    <ObjectRenderer {...item.attributes.shipping_information} />
                                                                                </td>
                                                                                <td className="cart-block--offcanvas-cart-table__quantity">
                                                                                    billing_information:<br />
                                                                                    <ObjectRenderer {...item.attributes.billing_information} />
                                                                                </td>
                                                                                <td className="cart-block--offcanvas-cart-table__remove text-right">
                                                                                    <OrderItems mappedIncludes={mappedIncludes} orderItems={orderItems} />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        } */}

                                                        </React.Fragment>
                                                    )

                                                    const purchasableEntity = getRelationshipFromMappedIncludes(orderItems, 'purchased_entity', mappedIncludes); // type: 'commerce_product_variation--default' | 'commerce_product_variation--phone'
                                                    const product = getRelationshipFromMappedIncludes(purchasableEntity, 'product_id', mappedIncludes); // type: 'commerce_product--default' | 'commerce_product--phone'
                                                    const variation = getRelationshipFromMappedIncludes(product, 'variations', mappedIncludes);
                                                    // console.log('wishlistItem: ', wishlistItem) // the same ID is not available in other objects: purchasableEntity, product, variation!!! therefore we need to use it in props.onWishlistRemove
                                                    // console.log(orderItems)
                                                    // console.log('purchasableEntity: ', purchasableEntity)
                                                    // console.log('product: ', product)
                                                    // console.log('variation: ', variation)

                                                    // const item = purchasableEntity.attributes;

                                                    // const drupal_internal__product_id = product.attributes.drupal_internal__product_id;
                                                    // const commerceProduct = Product.getItemByProductId(drupal_internal__product_id, intl.locale);
                                                    // // console.log(commerceProduct)
                                                    // const commerceProductPurchasedVariartion = getProductPurchasedVariartion(commerceProduct, purchasableEntity);

                                                    return (
                                                        <tr key={purchasableEntity.attributes.id}>
                                                            {/* <td className="cart-block--offcanvas-cart-table__title w-50">
                                                                <Link to={product.attributes.path.alias}>
                                                                    <div style={{ width: `4rem` }} className="mr-3">
                                                                        <ProductImage defaultVariation={commerceProductPurchasedVariartion} />
                                                                    </div>
                                                                </Link>
                                                            </td> */}
                                                            <td className="cart-block--offcanvas-cart-table__title w-50">
                                                                <Link to={product.attributes.path.alias}>
                                                                    {purchasableEntity.attributes.title}{` `}{purchasableEntity.attributes.drupal_internal__variation_id}
                                                                </Link>
                                                            </td>
                                                            {/* <td className="cart-block--offcanvas-cart-table__quantity">
                                                                <input className="form-control" type={`number`} size={5} min={0} defaultValue={parseInt(purchasableEntity.attributes.quantity)} onChange={e => {
                                                                    dispatch(cartUpdateItem(item, {quantity: e.target.value}))
                                                                }} />
                                                            </td> */}
                                                            {/* <td className="cart-block--offcanvas-cart-table__price w-15">
                                                                {purchasableEntity.attributes.total_price.formatted}
                                                            </td> */}
                                                            {/* <td className="cart-block--offcanvas-cart-table__remove text-right">
                                                                <button className="btn btn-primary" onClick={() => props.onWishlistRemove(wishlistItem, authorization)}>Remove</button>
                                                            </td> */}
                                                        </tr>
                                                    )

                                                })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
        orders: state.shopReducers.order,
    }
};

const mapDispatchToProps = dispatch => ({
    onOrderFetch: (authorization) => dispatch(orderFetch(authorization)),
    // onOrderItemFetch: (authorization) => dispatch(wishlistFetch(authorization)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(Component));