import React from 'react';
import { connect } from 'react-redux'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { userRegister } from './../../Shop/ext/redux/actions/user'



class Component extends React.Component {
    state = {
        open: false,
        processing: false,
        mail: '',
        name: '',
        pass: '',
        error: null,
    };

    constructor(props) {
        super(props);

        this.t = this.props.intl.formatMessage;
    }

    // handleClickOpen = () => {
    //     this.setState({ open: true });
    // }

    // handleCancel = () => {
    //     this.setState({ open: false });
    // }

    handleSubmit = async (event) => {
        // event.preventDefault();
        this.setState({ processing: true });
        const { mail, name, pass } = this.state;

        try {
            await this.props.onUserRegister({
                mail: mail,
                name: name,
                pass: pass,
            });
            this.setState({ processing: false, open: false });
/*
            const response = await this.props.drupalOauthClient.handleLogin(username, password, '');
            // await this.props.drupalOauthClient.handleLogin(username, password, '');
            // console.log(response)
            if (typeof response === `undefined`) {
                throw new Error({
                    message: this.t({ id: "soft4net_form_login_form_error" }),
                });
            }

            // we were authenticated because no exception were thrown
            this.setState({ processing: false, open: false });
            this.props.updateAuthenticatedUserState(true);
*/
        } catch (err) {
            // console.log(err)
            this.setState({
                processing: false,
                // error: err.message,
                error: {
                    message: this.t({ id: "soft4net_form_login_form_error" }),
                },
            });
        }
    }

    render() {
        const isUserAuthenticated = this.props.userAuthenticated;
        // console.log('isUserAuthenticated: ', isUserAuthenticated);
    
        // const _token = this.props.drupalOauthClient.isLoggedIn();
        // console.log('_token: ', _token);

        if (isUserAuthenticated) {
            navigate(`/user/profile`);
        }

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Załóż konto</h2>
                            <br />
                            <TextField
                                error={this.state.error}
                                helperText={this.state.error && this.state.error.message}
                                autoFocus
                                margin="dense"
                                name="mail"
                                label={ this.t({ id: "soft4net_form_login_form_usermail" }) }
                                type="text"
                                fullWidth
                                onChange={event => this.setState({ [event.target.name]: event.target.value })}
                            />
                            <TextField
                                error={this.state.error}
                                helperText={this.state.error && this.state.error.message}
                                margin="dense"
                                name="name"
                                label={ this.t({ id: "soft4net_form_login_form_username" }) }
                                type="text"
                                fullWidth
                                onChange={event => this.setState({ [event.target.name]: event.target.value })}
                            />
                            <TextField
                                error={this.state.error}
                                helperText={this.state.error && this.state.error.message}
                                margin="dense"
                                name="pass"
                                label={ this.t({ id: "soft4net_form_login_form_password" }) }
                                type="password"
                                fullWidth
                                onChange={event => this.setState({ [event.target.name]: event.target.value })}
                            />

                            {/* <Button onClick={this.handleCancel} color="default">{ this.t({ id: "soft4net_form_action_cancel" }) }</Button> */}
                            {this.state.processing ? <CircularProgress /> : <Button onClick={(event) => {this.handleSubmit(event)}} color="primary" variant="contained">Register</Button>}
                        </div>                        
                        <div className="col-md-6">
                            <h2>Nie masz konta?</h2>
                            <br />
                            Dlaczego warto mieć konto w decolly.pl<br />
                            zamawiaj szybciej<br />
                            sprawdzaj poprzednie zamówienia<br />
                            korzystaj z rabatów i promocji<br />
                        </div>                        
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// const mapStateToProps = state => {
//     return {
//         orders: state.shopReducers.order,
//     }
// };
const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    onUserRegister: (formData) => dispatch(userRegister(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withDrupalOauthConsumer(Component)));