import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'
import { jsonapiClient } from 's4n-jsonapi-client'

const suppressException = process.env.SOFT4NET_CREATE_ACTION_THUNK_SUPPRESS_EXCEPTION === `true` ? true : false;

export const wishlistFetch = createActionThunk('WISHLIST_FETCH', async (authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    const { wishlist: { items, included } } = state.shopReducers;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'wishlist_fetch', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)

export const wishlistAdd = createActionThunk('WISHLIST_ADD', async (variation, authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    const { wishlist: { items, included } } = state.shopReducers;

    await jsonapiClient(process.env.REACT_APP_API_URL, 'wishlist_add', {
        parameters: {
            purchasedEntity: variation,
            authorization,
            // quantity: 1
        },
        options: {
            langcode: intl.locale
        },
    });

    store.dispatch(wishlistFetch(authorization));

    // we must return payload to reducer!!!
    return { wishlist: [], included: [] };
}, suppressException)

// THIS ACTION HAS NO REDUCER BECAUSE IT DOES NOT NEED IT, we can get data returned from remove request and inject it thanjks to this we do not need to make another call to wishlistFetch ??? !!!
export const wishlistRemove = createActionThunk('WISHLIST_REMOVE', async (variation, authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    const { wishlist: { items, included } } = state.shopReducers;

    await jsonapiClient(process.env.REACT_APP_API_URL, 'wishlist_remove', {
        parameters: {
            purchasedEntity: variation,
            authorization,
            // quantity: 1
        },
        options: {
            langcode: intl.locale
        },
    });

    store.dispatch(wishlistFetch(authorization));

    // we must return payload to reducer!!!
    // return { wishlist: [], included: [] };
}, suppressException)

export const wishlistUpdateItem = createActionThunk('WISHLIST_UPDATE_ITEM', async (variation, authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    const { wishlist: { items, included } } = state.shopReducers;

    await jsonapiClient(process.env.REACT_APP_API_URL, 'wishlist_update_item', {
        parameters: {
            purchasedEntity: variation,
            authorization,
            // quantity: 1
        },
        options: {
            langcode: intl.locale
        },
    });

    store.dispatch(wishlistFetch(authorization));

    // we must return payload to reducer!!!
    return { wishlist: [], included: [] };
}, suppressException)