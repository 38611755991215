import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { wishlistFetch, wishlistAdd, wishlistUpdateItem, wishlistRemove } from './../../../Shop/ext/redux/actions/wishlist'
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk";

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import withDrupalOauthConsumer from './../../../../drupal-oauth/withDrupalOauthConsumer';
import CommerceHelper from './../../../Shop/s4n/utils/commerce-helper'
import Preloader from './../../../Preloader'
import Product from './../../../../../model/commerce/product'
import ProductImage from './../../../Shop/Product/image'
import ProductPrice from './../../../Shop/Product/price'

import { cartAdd } from './../../../Shop/ext/redux/actions/cart'



const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    // props.onWishlistFetch(authorization); // @todo: run once at app start!!! running it here is not efficient

    const { wishlist: { items, included }, dispatch } = props;

    // const isUserAuthenticated = props.userAuthenticated;
    // console.log(isUserAuthenticated);
    // const token = props.drupalOauthClient.isLoggedIn();
    // console.log(token);
    const authorization = props.drupalOauthClient.isLoggedIn();

    // console.log(authorization)

    // we don';t need this check since we are inside <PrivateRoute /> component that check this for us!!!
    // if (!props.userAuthenticated) {
    //     return null;
    // }

    // const [wishlist, setWishlist] = useState([]);

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {       
        await props.onWishlistFetch(authorization); // @todo: run once at app start!!! running it here is not efficient

        // const wishlist = await props.onWishlistFetch(authorization);
        // // console.log(wishlist)
        // if (wishlist.included) {
        //     const wishlist_commerce_product_variation = wishlist.included.filter(item => item.attributes);
        //     setWishlist(wishlist_commerce_product_variation);
        // }
    }

    // console.log(items)
    // console.log(included) // we should use commerce_product_variation--default commerce_product_variation--phone
    // return null;

    // if (!(included)) {
    //     return <Preloader />
    // }

    /**
     * @see: https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/includes#s-fetch-all-at-once-using-include
     * Also, notice how there is now a data key in each relationship object. 
     * That let's you correlate the included resource objects with the resource objects that referenced them.
     * 
     * data field in JSON:API gets populated when we use incude in request
     */
    const itemsRelationship = items && items[0] ? items[0].relationships.wishlist_items : null;
    // const itemsRelationship = items.relationships.wishlist_items // was

    const mappedIncludes = getMappedIncludes(included)
    // console.log(mappedIncludes)
    // return null;

    let wishlistItems = [];
    try {
        wishlistItems = getRelationshipFromMappedIncludes(items[0], 'wishlist_items', mappedIncludes);
    } catch (error) {
        console.log(error)
    }
    // console.log(wishlistItems)
    // return null;


    if (!itemsRelationship || !itemsRelationship.data || itemsRelationship.data.length === 0 || wishlistItems.length === 0) {
        return (<div className={`container`} key={`no_items`}>
            <div className={`row`}>
                <div className={`col-sm-12`}><p>{ t({ id: `soft4net_shop_commerce_wishlist_page_list_empty` }) }</p></div>
            </div>
        </div>)
    }



    // let wishlistItems = mappedIncludes[`commerce_wishlist_item--commerce_product_variation`];
    // let wishlistItemsKeys = Object.keys(wishlistItems)
    // console.log(wishlistItems)
    // return null;

    return (
        <React.Fragment>
            <div 
                // _key={items.id} 
            className={`container`}>
                <div className={`row`}>
                    <div className={`col`}>

                        <h1>{ t({ id: `soft4net_shop_commerce_wishlist_page_title` }) }</h1>
                        <table className={`table my-4`}>
                            <thead>
                                <tr>
                                    <th className={`image`}></th>
                                    <th className={`title`}>{ t({ id: `soft4net_shop_commerce_wishlist_page_list_header_product_title` }) }</th>
                                    <th className={`price`}>{ t({ id: `soft4net_shop_commerce_wishlist_page_list_header_product_price` }) }</th>
                                    <th className={`actions`}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {wishlistItems.map(wishlistItem => {
                                    if (!wishlistItem) {
                                        return null;
                                    }
/*
                                    includes: [
                                        // 'wishlist_items', 
                                        // 'wishlist_items.purchasable_entity',
                                        'wishlist_items.purchasable_entity.product_id',
                                        // `wishlist_items.purchasable_entity.product_id.variations`,
                                    ]
*/
                                    const purchasableEntity = getRelationshipFromMappedIncludes(wishlistItem, 'purchasable_entity', mappedIncludes); // type: 'commerce_product_variation--default' | 'commerce_product_variation--phone'
                                    const product = getRelationshipFromMappedIncludes(purchasableEntity, 'product_id', mappedIncludes); // type: 'commerce_product--default' | 'commerce_product--phone'
                                    const variation = getRelationshipFromMappedIncludes(product, 'variations', mappedIncludes);
// console.log('wishlistItem: ', wishlistItem) // the same ID is not available in other objects: purchasableEntity, product, variation!!! therefore we need to use it in props.onWishlistRemove
                                    // console.log('purchasableEntity: ', purchasableEntity)
                                    // console.log('product: ', product)
                                    // console.log('variation: ', variation)

                                    // const item = purchasableEntity.attributes;

                                    // s4n
                                    const drupal_internal__product_id = product.attributes.drupal_internal__product_id;
                                    const commerceProduct = Product.getItemByProductId(drupal_internal__product_id, intl.locale);
                                    // console.log(commerceProduct)
									const commerceProductPurchasedVariartion = CommerceHelper.getProductPurchasedVariartion(commerceProduct, purchasableEntity);

                                    return (
                                        <tr key={purchasableEntity.attributes.id}>
                                            <td className="image">
                                                <Link to={product.attributes.path.alias}>
                                                    <ProductImage defaultVariation={commerceProductPurchasedVariartion} />
                                                </Link>
                                            </td>
                                            <td className="title">
                                                <div className={`row mobile`}>
                                                    <div className={`col-5 image`}>
                                                        <Link to={product.attributes.path.alias}>
                                                            <ProductImage defaultVariation={commerceProductPurchasedVariartion} />
                                                        </Link>
                                                    </div>
                                                    <div className={`col-7 price`}>
                                                        <ProductPrice selectedVariation={purchasableEntity.attributes} _printLabel={true} />
                                                    </div>
                                                </div>

                                                <Link to={product.attributes.path.alias}>
                                                    {purchasableEntity.attributes.title}
                                                    {/* {` `}{purchasableEntity.attributes.drupal_internal__variation_id} */}
                                                </Link>
                                            </td>
                                            {/* <td className="quantity">
                                                <input className="form-control" type={`number`} size={5} min={0} defaultValue={parseInt(purchasableEntity.attributes.quantity)} onChange={e => {dispatch(cartUpdateItem(item, {quantity: e.target.value}))}} />
                                            </td> */}
                                            <td className="price">
                                                <ProductPrice selectedVariation={purchasableEntity.attributes} _printLabel={true} />
                                            </td>
                                            <td className="actions">
                                                <div className={`wrapper`}>
                                                    <button className="btn btn-danger remove" onClick={() => props.onWishlistRemove(wishlistItem, authorization)}>
                                                        { t({ id: `soft4net_shop_commerce_wishlist_page_list_remove` }) }
                                                    </button>
                                                    <button className="btn btn-success add-to-cart" onClick={() => props.onAddToCart(purchasableEntity)}>
                                                        { t({ id: `soft4net_shop_commerce_product_page_add_to_cart` }) }
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )

                                    // const purchaseEntity = getRelationshipFromMappedIncludes(item, 'purchased_entity', mappedIncludes);
                                    // const product = getRelationshipFromMappedIncludes(purchaseEntity, 'product_id', mappedIncludes);

                                    // return (
                                    //     <tr key={item.id}>
                                    //         <td className="cart-block--offcanvas-cart-table__title w-50">
                                    //             <Link to={product.attributes.path.alias}>{item.attributes.title}</Link>
                                    //         </td>
                                    //         <td className="cart-block--offcanvas-cart-table__quantity">
                                    //             <input className="form-control" type={`number`} size={5} min={0} defaultValue={parseInt(item.attributes.quantity)} onChange={e => {
                                    //                 dispatch(cartUpdateItem(item, {quantity: e.target.value}))
                                    //             }} />
                                    //         </td>
                                    //         <td className="cart-block--offcanvas-cart-table__price w-15">
                                    //             {item.attributes.total_price.formatted}
                                    //         </td>
                                    //         <td className="cart-block--offcanvas-cart-table__remove text-right">
                                    //             <button className="btn btn-primary" onClick={() => { dispatch(cartRemove(item)) }}>Remove</button>
                                    //         </td>
                                    //     </tr>
                                    // )
                                })}
                            </tbody>
                            <tfoot>
                            </tfoot>
                        </table>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
        wishlist: state.shopReducers.wishlist,
    }
};

const mapDispatchToProps = dispatch => ({
    onAddToCart: (activeVariation, quantity) => dispatch(cartAdd(activeVariation, quantity)),
    onWishlistFetch: (authorization) => dispatch(wishlistFetch(authorization)),
    onWishlistAdd: (activeVariation, authorization) => dispatch(wishlistAdd(activeVariation, authorization)),
    onWishlistRemove: (activeVariation, authorization) => dispatch(wishlistRemove(activeVariation, authorization)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(Component));